<template>
  <button @click="loginFacebook($event)" class="social-signin-fb">
    <i class="icon-fb"></i>
    Continue with Facebook
  </button>
</template>
<script>
import AutorizationService from '@/services/AutorizationService';
import autorization from '@/mixins/autorization';

export default {
  mixins: [autorization],
  methods: {
    APIFacebook() {
      window.FB.api('/me?fields=name,email', async (response) => {
        const data = {
          provider: 'facebook',
          client_id: response.id,
          data: response,
          email: response.email,
          name: response.name,
          avatar: response.avatar,
        };
        try {
          const { headers } = await AutorizationService.connect(data);
          this.setAutorization(headers);
        } catch (error) {
          console.log(error);
        }
      });
    },
    loginFacebook(event) {
      event.preventDefault();
      window.FB.login(
        (response) => {
          if (response.status === 'connected') {
            this.APIFacebook();
          } else {
            console.log('The person is not logged into your webpage or we are unable to tell.');
          }
        },
        {
          scope: 'email',
          return_scopes: true,
        },
      );
    },
  },
};
</script>
