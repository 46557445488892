<template>
  <button id="googleAuth" class="social-signin-google">
    <i class="icon-google"></i>
    Continue with Google
  </button>
</template>
<script>
import AutorizationService from '@/services/AutorizationService';
import autorization from '@/mixins/autorization';

export default {
  mixins: [autorization],
  mounted() {
    this.$nextTick(() => {
      this.startApp();
    });
  },
  methods: {
    attachSignin(auth2, element) {
      auth2.attachClickHandler(
        element,
        {},
        async (googleUser) => {
          const profile = googleUser.getBasicProfile();
          const idToken = googleUser.getAuthResponse().id_token;
          const data = {
            provider: 'google',
            client_id: profile.getId(),
            data: googleUser,
            email: profile.getEmail(),
            name: profile.getName(),
            avatar: profile.getImageUrl(),
            id_token: idToken,
          };
          try {
            const { headers } = await AutorizationService.connect(data);
            this.setAutorization(headers);
          } catch (error) {
            console.log(error);
          }
        },
        (error) => {
          console.log(error);
        },
      );
    },
    startApp() {
      window.gapi.load('auth2', () => {
        const auth2 = window.gapi.auth2.init({
          client_id: '755326589580-711qhr6neli26tkshsvt468rob9oppfs.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
        });
        this.attachSignin(auth2, document.querySelector('#googleAuth'));
      });
    },
  },
};
</script>
