<template>
  <div class="container">
    <div class="registration__inner">
      <div class="crazy-fly">
        <img src="@/assets/images/crazy-fly.svg" alt="" />
      </div>
      <div class="registration__item">
        <h2 class="registration__title">{{content.title}}</h2>
        <div class="registration__text" v-html="content.block[0]"></div>
      </div>
      <div class="registration__item">
        <div class="form-block">
          <h3 class="form-block__title">Register</h3>
          <Alert :variant="variant" v-if="text">{{ text }}</Alert>
          <div class="form-block__body">
            <ValidationObserver slim v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(validate)">
                <ValidationProvider name="Login" rules="required|email" v-slot="{ errors, failed }">
                  <div class="form-row" :class="{ 'has-error': failed }">
                    <input
                      name="login"
                      v-model="form.email"
                      class="form-control"
                      type="text"
                      placeholder="Email"
                    />
                    <span class="help-message">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="First name" rules="required" v-slot="{ errors, failed }">
                  <div class="form-row" :class="{ 'has-error': failed }">
                    <input
                      name="first_name"
                      v-model="form.first_name"
                      class="form-control"
                      type="text"
                      placeholder="First name"
                    />
                    <span class="help-message">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="Last name" rules="required" v-slot="{ errors, failed }">
                  <div class="form-row" :class="{ 'has-error': failed }">
                    <input
                      name="last_name"
                      v-model="form.last_name"
                      class="form-control"
                      type="text"
                      placeholder="Last name"
                    />
                    <span class="help-message">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="Password"
                  rules="required|min:8|hardPassword"
                  v-slot="{ errors, failed }"
                >
                  <div class="form-row" :class="{ 'has-error': failed }">
                    <input
                      name="password"
                      v-model="form.password"
                      ref="password"
                      class="form-control"
                      type="password"
                      placeholder="Password"
                    />
                    <span class="help-message">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="Password repeat"
                  rules="required|confirmed:Password|min:8"
                  v-slot="{ errors, failed }"
                >
                  <div class="form-row" :class="{ 'has-error': failed }">
                    <input
                      name="password_repeat"
                      v-model="form.password_repeat"
                      class="form-control"
                      type="password"
                      placeholder="Password repeat"
                    />
                    <span class="help-message">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <div class="wrapRecaptcha">
                  <vue-recaptcha
                    ref="recaptcha"
                    :sitekey="googleCaptchaKey"
                    @verify="onSubmit"
                    @expired="onCaptchaExpired"
                  />
                </div>
                <div class="btns-group--right">
                  <button type="submit" class="btn btn-primary">Register</button>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <hr class="divider-text" />
          <div class="social-signin">
            <ButtonFacebook />
            <ButtonGoogle />
          </div>
          <hr class="divider" />
          <div class="form-bottom">
            Already have an account?
            <router-link class="link" :to="{ name: 'login' }">Login here</router-link>
          </div>
        </div>
      </div>
      <Modal
        @close="hideModal('modalRegister')"
        id="modalRegister"
        title="Registered successfully"
        @ok="toLogin"
      >
        <p>Your request has been received! You should receive an email about application status.</p>
      </Modal>
    </div>
  </div>
</template>
<script>
import AutorizationService from '@/services/AutorizationService';

import ButtonFacebook from '@/components/buttons/ButtonFacebook.vue';
import ButtonGoogle from '@/components/buttons/ButtonGoogle.vue';
import Modal from '@/components/Modal.vue';
import Alert from '@/components/Alert.vue';

import autorization from '@/mixins/autorization';
import alert from '@/mixins/alert';
import modal from '@/mixins/modal';
import content from '@/mixins/content';

import VueRecaptcha from 'vue-recaptcha';

export default {
  mixins: [autorization, alert, modal, content],
  components: {
    ButtonFacebook,
    ButtonGoogle,
    Modal,
    Alert,
    VueRecaptcha,
  },
  data() {
    return {
      googleCaptchaKey: process.env.VUE_APP_GOOGLE_CAPTCHA_KEY,
      form: {
        email: undefined,
        first_name: undefined,
        last_name: undefined,
        password: undefined,
        password_repeat: undefined,
      },
    };
  },
  methods: {
    toLogin() {
      this.$router.push({ name: 'login' });
    },
    async onSubmit(recaptchaToken) {
      this.form['g-recaptcha-response'] = recaptchaToken;
    },
    async validate() {
      if (!this.form['g-recaptcha-response']) {
        this.$refs.recaptcha.execute();
      } else {
        try {
          this.removeAlert();
          await AutorizationService.register(this.form);
          this.showModal('modalRegister');
        } catch (e) {
          document.querySelector('#app').scrollIntoView(true);
          this.$refs.recaptcha.reset();
          this.setAlert({ textArg: e.response.data[0].message, variantArg: 'danger' });
        }
      }
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
  },
  mounted() {
    document.body.classList.add('page-layout');
    document.body.style.background = '';
  },
};
</script>
<style lang="scss" scoped>
.wrapRecaptcha{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
