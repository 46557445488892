var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"registration__inner"},[_vm._m(0),_c('div',{staticClass:"registration__item"},[_c('h2',{staticClass:"registration__title"},[_vm._v(_vm._s(_vm.content.title))]),_c('div',{staticClass:"registration__text",domProps:{"innerHTML":_vm._s(_vm.content.block[0])}})]),_c('div',{staticClass:"registration__item"},[_c('div',{staticClass:"form-block"},[_c('h3',{staticClass:"form-block__title"},[_vm._v("Register")]),(_vm.text)?_c('Alert',{attrs:{"variant":_vm.variant}},[_vm._v(_vm._s(_vm.text))]):_vm._e(),_c('div',{staticClass:"form-block__body"},[_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validate)}}},[_c('ValidationProvider',{attrs:{"name":"Login","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"form-row",class:{ 'has-error': failed }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",attrs:{"name":"login","type":"text","placeholder":"Email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('span',{staticClass:"help-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"form-row",class:{ 'has-error': failed }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.first_name),expression:"form.first_name"}],staticClass:"form-control",attrs:{"name":"first_name","type":"text","placeholder":"First name"},domProps:{"value":(_vm.form.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "first_name", $event.target.value)}}}),_c('span',{staticClass:"help-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"form-row",class:{ 'has-error': failed }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.last_name),expression:"form.last_name"}],staticClass:"form-control",attrs:{"name":"last_name","type":"text","placeholder":"Last name"},domProps:{"value":(_vm.form.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "last_name", $event.target.value)}}}),_c('span',{staticClass:"help-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|min:8|hardPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"form-row",class:{ 'has-error': failed }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],ref:"password",staticClass:"form-control",attrs:{"name":"password","type":"password","placeholder":"Password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('span',{staticClass:"help-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password repeat","rules":"required|confirmed:Password|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('div',{staticClass:"form-row",class:{ 'has-error': failed }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password_repeat),expression:"form.password_repeat"}],staticClass:"form-control",attrs:{"name":"password_repeat","type":"password","placeholder":"Password repeat"},domProps:{"value":(_vm.form.password_repeat)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password_repeat", $event.target.value)}}}),_c('span',{staticClass:"help-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"wrapRecaptcha"},[_c('vue-recaptcha',{ref:"recaptcha",attrs:{"sitekey":_vm.googleCaptchaKey},on:{"verify":_vm.onSubmit,"expired":_vm.onCaptchaExpired}})],1),_c('div',{staticClass:"btns-group--right"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("Register")])])],1)]}}])})],1),_c('hr',{staticClass:"divider-text"}),_c('div',{staticClass:"social-signin"},[_c('ButtonFacebook'),_c('ButtonGoogle')],1),_c('hr',{staticClass:"divider"}),_c('div',{staticClass:"form-bottom"},[_vm._v(" Already have an account? "),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'login' }}},[_vm._v("Login here")])],1)],1)]),_c('Modal',{attrs:{"id":"modalRegister","title":"Registered successfully"},on:{"close":function($event){return _vm.hideModal('modalRegister')},"ok":_vm.toLogin}},[_c('p',[_vm._v("Your request has been received! You should receive an email about application status.")])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"crazy-fly"},[_c('img',{attrs:{"src":require("@/assets/images/crazy-fly.svg"),"alt":""}})])}]

export { render, staticRenderFns }